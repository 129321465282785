import React, {Component} from 'react';
import {Col, Collapse, Container, Row, Stack} from "react-bootstrap";
import { ReactComponent as Check } from "../assets/Check.svg";
import { ReactComponent as Cross } from "../assets/Cross.svg";
import { ReactComponent as Arrow } from "../assets/Arrow Left.svg";


class FormStep4Agent extends Component {

    render() {
        return (
            <Container fluid className="d-flex flex-column Form-body Peach mt-3">

                    <Row style={{width: "fit-content"}} className="col-sm d-block d-sm-none">
                        <button  className="prev-button" onClick={() => {this.props.prevStep()}}><Arrow/> &nbsp; Back</button>
                    </Row>
                <Row>
                    <Col>
                        <Stack className="top-row" direction="horizontal" gap={2}>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator"></div>
                        </Stack>
                    </Col>
                </Row>
                <Row className= "flex-grow-1 align-items-center middle-row">
                    <Col  xs={12} lg = {6}>
                        <p className="text"> Are you satisfied communicating with Support Agent {this.props.inputValues.agentName}?</p>
                    </Col>
                    <Col  xs={12} lg = {6} style={{maxHeight: "295px"}}>
                        <div className="align-items-end">
                            <button
                                className={`form-toggle-button ${this.props.inputValues.agent === true ? ' toggled' : ''}`}
                                onClick={() => {
                                    this.props.handleButtonToggle("agent", true);
                                    this.props.handleTextChange("agentDetails","")
                                }}><Check className = "form-toggle-button-icon" stroke = {`${this.props.inputValues.agent === true ? "#FF8C5B"  : 'white'}`}/> Yes
                            </button>
                            <Collapse in={this.props.inputValues.agent}>
                                <div style={{width: "100%"}}>
                                    <textarea className={`form-textarea`} rows="5"
                                              value = {this.props.inputValues.agentDetails}
                                              placeholder="Type your answer here ..."
                                              onChange={event => (this.props.handleTextChange("agentDetails", event.target.value))}
                                    ></textarea>
                                </div>
                            </Collapse>


                            <button
                                className={`form-toggle-button ${this.props.inputValues.agent === false ? ' toggled' : ''}`}
                                onClick={() => {
                                    this.props.handleButtonToggle("agent", false);
                                    this.props.handleTextChange("agentDetails","")
                                }}><Cross className="form-toggle-button-icon"
                                          stroke={`${this.props.inputValues.agent === false ? "#FF8C5B" : 'white'}`}/> No
                            </button>
                            <Collapse in={this.props.inputValues.agent === false}>
                                <div style={{width: "100%"}}>
                                    <textarea className={`form-textarea`} rows="5"
                                              value = {this.props.inputValues.agentDetails}
                                              placeholder="Tell us what went wrong ..."
                                              onChange={event => (this.props.handleTextChange("agentDetails", event.target.value))}></textarea>
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
                <Row className="bottom-row align-items-center">
                    <Col style={{padding:"0"}} className="col-sm d-none d-sm-block">
                        <button className="prev-button" onClick={() => {
                            this.props.prevStep()
                        }}>&lt; Previous Question
                        </button>
                    </Col>
                    <Col className="text-end">
                        {this.props.inputValues.agent === null ?
                            <button className="next-button feedback disabled" disabled onClick={() => {
                            }}>Next
                            </button> :
                            <button className="next-button feedback" onClick={() => {
                                this.props.nextStep()
                            }}>Next
                            </button>}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FormStep4Agent;