import React, { Component } from 'react';
import {Col, Container, Row, Stack} from "react-bootstrap";
import { ReactComponent as Check } from "../assets/Check.svg";
import { ReactComponent as Cross } from "../assets/Cross.svg";
import { ReactComponent as Arrow } from "../assets/Arrow Left.svg";


class FormStep2Feedback extends Component {


    render() {
        return (
            <Container fluid className="d-flex flex-column Form-body Peach mt-3">
                <Row style={{width: "fit-content"}} className="col-sm d-block d-sm-none">
                 <button  className="prev-button" onClick={() => {this.props.prevStep()}}><Arrow/> &nbsp; Back</button>
                </Row>
                <Row >
                    <Col>
                        <Stack className="top-row" direction="horizontal" gap={2}>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator"></div>
                            <div className="indicator"></div>
                            <div className="indicator"></div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="flex-grow-1 align-items-center middle-row">
                    <Col xs={12} lg = {6} className="align-items-start">
                        <p className="text"> Do you want to share a detailed feedback?</p>
                    </Col>
                    <Col className="align-items-center"    xs={12} lg = {6}>
                        <Stack className="align-items-end">
                            <button
                                className={`form-toggle-button ${this.props.inputValues.detailFeedback === true ? ' toggled bounce' : ''}`}
                                onClick={() => {
                                    this.props.handleButtonToggle("detailFeedback", true)
                                }}> {this.props.inputValues.detailFeedback === true ? <Check className = "form-toggle-button-icon" stroke="#FF8C5B"/> :
                                <Check className = "form-toggle-button-icon"/>}Yes
                            </button>
                            <button
                                className={`form-toggle-button ${this.props.inputValues.detailFeedback === false ? ' toggled bounce' : ''}`}
                                onClick={() => {
                                    this.props.handleButtonToggle("detailFeedback", false)
                                }}>{this.props.inputValues.detailFeedback === false ?
                                <Cross className = "form-toggle-button-icon" stroke="#FF8C5B"/>:
                                <Cross className = "form-toggle-button-icon"/>} No
                            </button>
                        </Stack>
                    </Col>
                </Row>
                <Row className="bottom-row align-items-center">
                    <Col className="col-sm d-none d-sm-block">
                        <button className="prev-button" onClick={() => {
                            this.props.prevStep()
                        }}>&lt; Previous Question
                        </button>
                    </Col>
                    <Col className="text-end">
                        {
                            this.props.inputValues.detailFeedback === null ?
                                <button className="next-button feedback disabled" disabled onClick={() => {
                                }}>Next
                                </button>:
                                this.props.inputValues.detailFeedback === false ?
                                    <button className="next-button feedback" onClick={() => {
                                        this.props.onSubmit()
                                    }}>Submit
                                    </button>
                                    :
                                    <button className="next-button feedback" onClick={() => {
                                        this.props.nextStep()
                                    }}>Next
                                    </button>}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FormStep2Feedback;