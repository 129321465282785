import React, { Component } from 'react';
import {Col, Collapse, Container, Row, Stack} from "react-bootstrap";
import { ReactComponent as Check } from "../assets/Check.svg";
import { ReactComponent as Cross } from "../assets/Cross.svg";
import { ReactComponent as Arrow } from "../assets/Arrow Left.svg";


class FormStep3Resolution extends Component {

    render() {
        return (
            <Container fluid className="d-flex flex-column Form-body Peach mt-3">
                <Row style={{width: "fit-content"}} className="col-sm d-block d-sm-none">
                    <button  className="prev-button" onClick={() => {this.props.prevStep()}}><Arrow/> &nbsp; Back</button>
                </Row>
                <Row>
                    <Col>
                        <Stack className="top-row" direction="horizontal" gap={2}>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator"></div>
                            <div className="indicator"></div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="flex-grow-1 align-items-center middle-row">
                    <Col  xs={12} lg = {6} className="align-items-start">
                        <p className="text"> Are you satisfied with how your case was resolved?</p>
                    </Col>
                    <Col className="align-items-center" xs={12} lg = {6} style={{maxHeight: "295px"}}>
                        <div className="align-items-end">
                            <button
                                className={`form-toggle-button ${this.props.inputValues.resolution === true ? ' toggled' : ''}`}
                                onClick={() => {
                                    this.props.handleButtonToggle("resolution", true); this.props.handleTextChange("resolutionDetails","")
                                }}><Check className = "form-toggle-button-icon" stroke = {`${this.props.inputValues.resolution === true ? "#FF8C5B"  : 'white'}`}/> Yes
                            </button>
                            <Collapse in={this.props.inputValues.resolution}>
                                <div style={{position:"relative", width: "100%"}}>
                                    <textarea className={`form-textarea`} rows="5"
                                              value = {this.props.inputValues.resolutionDetails}
                                              placeholder="Type your answer here ..." onChange={event => (this.props.handleTextChange("resolutionDetails", event.target.value))}></textarea>
                                    {/*<div*/}
                                    {/*    style={{*/}
                                    {/*        position: 'absolute',*/}
                                    {/*        fontSize: '0.875rem',*/}
                                    {/*        color: 'white',*/}
                                    {/*        bottom: '5px',*/}
                                    {/*        left: '5px',*/}
                                    {/*        pointerEvents: 'none',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    {this.props.inputValues.resolutionDetails ? this.props.inputValues.resolutionDetails.length : 0} / 1000*/}
                                    {/*</div>*/}
                                </div>
                            </Collapse>
                            <button
                                className={`form-toggle-button ${this.props.inputValues.resolution === false ? ' toggled' : ''}`}
                                onClick={() => {
                                    this.props.handleButtonToggle("resolution", false); this.props.handleTextChange("resolutionDetails","")
                                }}><Cross className = "form-toggle-button-icon" stroke = {`${this.props.inputValues.resolution === false ? "#FF8C5B"  : 'white'}`} /> No
                            </button>
                            <Collapse in={this.props.inputValues.resolution === false}>
                                <div style={{width: "100%"}}>
                                    <textarea className={`form-textarea`} rows="5"
                                              placeholder="Type your answer here ..."
                                              value = {this.props.inputValues.resolutionDetails}
                                              onChange={event => (this.props.handleTextChange("resolutionDetails", event.target.value))}
                                    ></textarea>
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
                <Row className="bottom-row align-items-center">
                    <Col style={{padding:"0"}} className="col-sm d-none d-sm-block">
                        <button className="prev-button" onClick={() => {
                            this.props.prevStep()
                        }}>&lt; Previous Question
                        </button>
                    </Col>
                    <Col className="text-end">
                        {this.props.inputValues.resolution === null ?
                        <button className="next-button feedback disabled" disabled onClick={() => {
                        }}>Next
                        </button>:

                        <button className="next-button feedback" onClick={() => {
                            this.props.nextStep()
                        }}>Next
                        </button>}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FormStep3Resolution;