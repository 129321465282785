import React, { Component } from 'react';
import {Col, Container, Row, Stack} from "react-bootstrap";
import { ReactComponent as NeutralResponse } from "../assets/Neutral Response Button.svg";
import { ReactComponent as PositiveResponse } from "../assets/Positive Response Button.svg";
import { ReactComponent as NegativeResponse } from "../assets/Negative Response Button.svg";


class FormStep1RateAssistance extends Component {

    render() {
        return (
            <Container fluid className="d-flex flex-column Form-body mt-3">
                <Row style={{width: "fit-content"}} className="col-sm d-block d-sm-none">
                    <button  className="prev-button" style={{visibility: "hidden"}} onClick={() => {}}>&nbsp; Back</button>
                </Row>
                <Row>
                    <Col>
                        <Stack className="top-row" direction="horizontal" gap={2}>
                            <div className="indicator Active"></div>
                            <div className="indicator"></div>
                            <div className="indicator"></div>
                            <div className="indicator"></div>
                            <div className="indicator"></div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="flex-grow-1 align-items-center middle-row">

                    <Col lg={6} xs={12}>
                        <p className="text"> Please rate the assistance in Support Case #{this.props.inputValues.ticketID}</p>
                    </Col>
                    <Col className="d-flex justify-content-center justify-content-lg-end" lg={6} xs={12}>
                        <Stack direction="horizontal" gap={2}>
                            <button
                                className={`toggle-emoji-button  ${this.props.inputValues.toggle === "negative" ? 'toggled bounce' : ''}`}
                                type={"button"} onClick={() => {
                                this.props.handleButtonToggle("negative")
                            }}>{this.props.inputValues.toggle === "negative" ? <NegativeResponse className="img-fluid" fill = "#FF6B5B" fillOpacity = "1" /> : <NegativeResponse className="img-fluid" />}</button>
                            <button
                                className={`toggle-emoji-button  ${this.props.inputValues.toggle === "neutral" ? 'toggled bounce' : ''} `}
                                type={"button"} onClick={() => {
                                this.props.handleButtonToggle("neutral")
                            }}>{this.props.inputValues.toggle === "neutral" ? <NeutralResponse className="img-fluid" fill = "#FF6B5B" fillOpacity = "1" /> : <NeutralResponse className="img-fluid" />}</button>
                            <button
                                className={`toggle-emoji-button  ${this.props.inputValues.toggle === "positive" ? 'toggled bounce' : ''}`}
                                type={"button"} onClick={() => {
                                this.props.handleButtonToggle("positive")
                            }}>{this.props.inputValues.toggle === "positive" ? <PositiveResponse className="img-fluid" fill = "#FF6B5B" fillOpacity = "1" /> : <PositiveResponse className="img-fluid" />}</button>
                        </Stack>
                    </Col>

                </Row>
                <Row className="bottom-row">
                    <Col className="text-end">
                        {this.props.inputValues.toggle !== "" ?
                            <button className="next-button rate" onClick={() => {
                                this.props.nextStep()
                            }}>Next</button> :
                            <button className="next-button rate disabled" disabled onClick={() => {
                            }}>Next</button>
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FormStep1RateAssistance;