import React, { Component } from 'react';
import {Container} from "react-bootstrap";
import { ReactComponent as Palm } from "../assets/Palm.svg";
import { ReactComponent as NCLogo } from "../assets/nordcurrent-logo.svg";



class FormThankYou extends Component {


    render() {
        //525px
        return (
            <Container className="Form-body form-thank-you Rate justify-content-end">
                <NCLogo className="form-thank-you-logo"/>
                <Palm className="intersect"/>
                <picture>
                    <source media="(max-width: 1000px)" srcSet={"https://djlbthceki3s9.cloudfront.net/Characters_2.webp"}/>
                    <source media="(min-width: 1001px)" srcSet={"https://djlbthceki3s9.cloudfront.net/Group_Image.webp"}/>
                    <img className="group-image img-fluid" src={"https://djlbthceki3s9.cloudfront.net/Group_Image.webp"} alt="Responsive"/>
                </picture>
                <p className="form-thank-you-text">Thank you!</p>
                <p className="form-thank-you-text small">Feel free to contact us anytime!</p>
            </Container>
        );
    }
}

export default FormThankYou;