import React, { Component } from 'react';
import {Container} from "react-bootstrap";
import ncLogo from "../assets/nordcurrent-logo.svg";


class FormHeader extends Component {

    render() {
        var headerColor = '';
        if(this.props.step >= 1){
            headerColor = "Peach"
        }
        return (
            <Container className={`Form-header ${headerColor}`}>
                <img className="Form-header-logo" src={ncLogo} alt="Nordcurrent"/>
            </Container>
        );
    }
}

export default FormHeader;