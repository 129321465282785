import React, { Component } from 'react';
import {Col, Container, Row, Stack} from "react-bootstrap";
import { ReactComponent as Arrow } from "../assets/Arrow Left.svg";


class FormStep5Improvement extends Component {


    render() {
        console.log(this.props.inputValues.improvementsDetails)
        return (
            <Container fluid className="d-flex flex-column Form-body Peach mt-3">
                    <Row style={{width: "fit-content"}} className="col-sm d-block d-sm-none">
                        <button  className="prev-button" onClick={() => {this.props.prevStep()}}><Arrow/> &nbsp; Back</button>
                    </Row>
                <Row>
                    <Col>
                        <Stack className="top-row" direction="horizontal" gap={2}>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                            <div className="indicator Active"></div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="flex-grow-1 align-items-center middle-row">
                    <Col  xs={12} lg = {6}>
                        <p className="text">Any suggestions for our improvement?</p>
                    </Col>
                    <Col  xs={12} lg = {6} className="d-flex justify-content-end">
                        <textarea className = "form-textarea Large" rows = "5" placeholder="Type your answer here ..."
                                  value={this.props.inputValues.improvementsDetails}
                                  onChange={event => (this.props.handleTextChange("improvementsDetails", event.target.value))}
                        ></textarea>
                    </Col>
                </Row>
                <Row className="bottom-row align-items-center">
                    <Col style={{padding:"0"}} className="col-sm d-none d-sm-block">
                        <button className="prev-button" onClick={() => {
                            this.props.prevStep()
                        }}>&lt; Previous Question
                        </button>
                    </Col>
                    <Col className="text-end">
                        <button className="next-button feedback" onClick={() => {
                            this.props.onSubmit()
                        }}>Submit
                        </button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FormStep5Improvement;