import React from 'react'
import './App.css';
import {Form} from "./Components/Form"


function App() {

  return (
      <div className="App">
          <Form/>
      </div>
  );
}

export default App;